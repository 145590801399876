



















































import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class ModalNoticeEdit extends Vue {
  @Prop({ default: "modal-edit-notice" }) private id!: string;
  @Prop({ default: "Ghi chú" }) private label!: string;
  @Prop({ default: "" }) private notice!: string;
  private editNotice: string = "";

  @Watch("notice")
  watchNotice() {
    this.editNotice = this.notice;
  }

  handleModalShow() {
    (this.$refs.notice as any)?.focus();
  }

  submitNotice() {
    this.$emit("submit", this.editNotice);
    this.$bvModal.hide(this.id);
  }
}
