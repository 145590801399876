































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
//@ts-ignore
import { VueGoodTable } from "vue-good-table";
import {
  BillViewModel,
  UserRole,
  BillCSV,
  PNote,
  CommonData,
  BillFilter,
  IdName,
  FilterProductTag
} from "@/models";
import ModalNoticeEdit from "@/components/Modal/ModalNoticeEdit.vue";
import ModalDocument from "@/components/Modal/ModalDocument.vue";
import TrackingBoard from "@/views/Dashboard/TrackingBoard.vue";
import CommonService from "@/services/CommonService";
import BillService from "@/services/BillService";
import DatePickerLang from "@/helpers/DatePickerLang";
//@ts-ignore
import VueTagsInput from "@johmun/vue-tags-input";
import ContractService from "@/services/ContractService";

@Component({
  components: {
    VueGoodTable,
    ModalNoticeEdit,
    ModalDocument,
    TrackingBoard,
    VueTagsInput
  }
})
export default class Dashboard extends Vue {
  private isLoading: boolean = true;
  private listCompany: CommonData[] = [];
  private listOrigin: CommonData[] = [];
  private listPort: CommonData[] = [];
  private listSupplier: string[] = [];
  private listCarrier: string[] = [];
  private columns: any[] = [];
  private rows: BillViewModel[] = [];

  private selectedRow: BillViewModel = new BillViewModel();
  private selectedPNote: PNote = new PNote();

  private eActionType: any = ActionType;
  private eCombobox: any = ECombobox;
  private datePickProp: DatePickerLang = new DatePickerLang();

  //csv
  private csvData: any = [];
  private csvHeader: any = {};

  //
  private contractId: string = "";
  private billId: string = "";

  //role
  private userRole: UserRole = this.$store.state.user.role;
  private eUserRole = UserRole;

  //advance search
  private filterOption: BillFilter = new BillFilter();
  private advanceSearchToggle: boolean = false;
  private chosseProd: string = "";
  private prodsOrigin: FilterProductTag[] = [];

  get prods() {
    return this.prodsOrigin.filter(i => {
      return i.text.toLowerCase().indexOf(this.chosseProd.toLowerCase()) !== -1;
    });
  }

  async created() {
    await this.getListProduct();
    this.loadBillFilter();
    this.getListContractAndBill();
    this.getListCompany();
    this.getListSupplier();
    this.getListCarrier();
    this.getListOrigin();
    this.getListPort();
    this.setCsvHeader();
  }

  loadBillFilter() {
    if (sessionStorage.getItem("tracking-filter")) {
      const filter = JSON.parse(
        sessionStorage.getItem("tracking-filter") as string
      );
      filter.etaFrom = this.toDateOrNull(filter.etaFrom);
      filter.etaTo = this.toDateOrNull(filter.etaTo);
      filter.signedDateFrom = this.toDateOrNull(filter.signedDateFrom);
      filter.signedDateTo = this.toDateOrNull(filter.signedDateTo);
      filter.clearFrom = this.toDateOrNull(filter.clearFrom);
      filter.clearTo = this.toDateOrNull(filter.clearTo);

      this.filterOption = filter;
    }
  }

  toDateOrNull(date: Date | undefined) {
    return date ? this.$moment(date).toDate() : null;
  }

  getListContractAndBill() {
    this.rows = [];
    this.getContractNoBill();
    this.getListBill();
  }

  formatDateOrNull(date: Date | undefined, isFrom: boolean) {
    return date
      ? this.$moment(date).format(
          isFrom ? "DD-MM-YYYY 00:00:00" : "DD-MM-YYYY 23:59:99"
        )
      : null;
  }

  /**
   * API get list bill
   */
  getListBill() {
    sessionStorage.setItem(
      "tracking-filter",
      JSON.stringify(this.filterOption)
    );

    const filter = new BillFilter(this.filterOption);
    const productIds = this.filterOption.selectedProds.map(
      item => item.value.id
    );
    filter.productIds = productIds;
    filter.signedDateFrom = this.formatDateOrNull(filter.signedDateFrom, true);
    filter.signedDateTo = this.formatDateOrNull(filter.signedDateTo, false);
    filter.etaFrom = this.formatDateOrNull(filter.etaFrom, true);
    filter.etaTo = this.formatDateOrNull(filter.etaTo, false);
    filter.clearFrom = this.formatDateOrNull(filter.clearFrom, true);
    filter.clearTo = this.formatDateOrNull(filter.clearTo, false);

    BillService.getLstData(filter)
      .then(res => {
        if (res.status === 200) {
          const data = res.data.map((item: any) => new BillViewModel(item));
          this.rows = [...this.rows, ...data];
          this.csvData = res.data.map((item: any) => new BillCSV(item));
        }
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => (this.isLoading = !this.isLoading));
  }

  getContractNoBill() {
    ContractService.getContractsNoBill()
      .then(res => {
        const data = res.data.map((item: any) => new BillViewModel(item));
        this.rows = [...data, ...this.rows];
      })
      .finally(() => (this.isLoading = !this.isLoading));
  }

  clearTrackingFilter() {
    this.filterOption = new BillFilter();
    sessionStorage.removeItem("tracking-filter");
    sessionStorage.removeItem("table-tracking-filter");
    this.getListContractAndBill();
    this.loadBillFilter();

    //reset filter
    this.$bus.$emit("loadTableTrackingFilter");
  }

  /**
   * call API get list supplier
   */
  async getListProduct() {
    return CommonService.getAllProuct()
      .then(res => {
        if (res.status === 200) {
          this.prodsOrigin = res.data.map(
            (item: CommonData) => new FilterProductTag(item)
          );
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  /**
   * Call API get list user
   */
  async getListOrigin() {
    CommonService.getAllCountry().then(res => {
      if (res.status === 200) {
        this.listOrigin = res.data;
      }
    });
  }
  /**
   * Call API get list port
   */
  async getListPort() {
    CommonService.getAllPort().then(res => {
      if (res.status === 200) {
        this.listPort = res.data;
      }
    });
  }

  /**
   * API get list company
   */
  async getListCompany() {
    return CommonService.getAllCompany()
      .then(res => {
        if (res.status === 200) {
          this.listCompany = res.data;
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  /**
   * call API get list supplier
   */
  async getListSupplier() {
    return CommonService.getAllSupplier()
      .then(res => {
        if (res.status === 200) {
          this.listSupplier = res.data;
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  /**
   * Call API get all ports
   */
  getListCarrier() {
    CommonService.getAllCarrier()
      .then(res => {
        if (res.status === 200) {
          this.listCarrier = res.data;
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  /**
   * Hanlge cbx for compay andy payment
   */
  handleSelectBoxChange(index: number, cb: ECombobox) {
    switch (cb) {
      case ECombobox.ORIGIN:
        this.filterOption.origin = this.listOrigin[index];
        break;
      case ECombobox.COMPANY:
        this.filterOption.company = this.listCompany[index];
        break;
      case ECombobox.PORT:
        this.filterOption.port = this.listPort[index];
        break;
    }
  }

  setCsvHeader() {
    this.csvHeader = {
      contractCode: "Mã hóa đơn",
      company: "Công ty",
      billNo: "Số bill",
      invoiceNo: "Số invoice",
      billDate: "Ngày INV",
      carrier: "Hãng tàu",
      shipCode: "Mã tàu",
      DP: "DP",
      ETD: "ETD",
      AP: "TP",
      ETA: "ETP",
      FP: "FP",
      ETF: "ETA",
      vetCheck: "Kiểm dịch",
      clearCheck: "Thông quan",
      vetDate: "Ngày kiểm dịch",
      clearDate: "Ngày thông quan",
      notice: "Ghi chú"
    };
  }
}

enum ActionType {
  DETAIL,
  UPDATE,
  STATUS
}

export enum ECombobox {
  ORIGIN,
  COMPANY,
  PORT
}
